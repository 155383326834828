<template>
  <div>
    <b-card-text>
      <b-row>
        <b-col
          cols="12"
          sm="6"
        >
          <b-form-group>
            <h6
              class="text-primary"
            >
              Nombre del Ajustador
            </h6>
            <b-form-input
              id="nombreAjustador"
              :value="coordinacion.nombreAjustador"
              disabled
            />
          </b-form-group>
        </b-col>
        <b-col
          cols="12"
          sm="6"
        >
          <b-form-group>
            <h6
              class="text-primary"
            >
              Tipo de Accidente
            </h6>
            <b-form-input
              id="tipoAccidente"
              :value="coordinacion.tipoAccidente.nombre"
              disabled
            />
          </b-form-group>
        </b-col>
        <b-col
          cols="12"
          sm="12"
        >
          <h6 class="text-primary">
            Observacion General
          </h6>
          <b-form-group>
            <b-form-textarea
              v-model="coordinacion.observacionGeneral"
              rows="2"
              no-resize
              disabled
            />
          </b-form-group>
        </b-col>
      </b-row>
      <componente-visualizacion :campos-deshabilitados="true" :coordinacion="coordinacion" />
      <hr>
      <b-row>
        <b-col cols="12">
          <h4 class="text-primary">
            Tracking
          </h4>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="12">
          <vue-good-table
            :columns="columns"
            :rows="coordinacion.seguimiento"
            style-class="vgt-table condensed"
          >
            <template
              slot="table-row"
              slot-scope="props"
            >
              <span v-if="props.column.field == 'detalle'">
                <b-button
                  v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                  v-b-modal.modal-1
                  variant="gradient-primary"
                  @click="mostrarImagen(props.row)"
                >
                  <feather-icon icon="EyeIcon" />
                </b-button>
                <!--p v-if="props.row.comentarios">{{ props.row.comentarios }}</p-->
              </span>
              <span v-else-if="props.column.field == 'fechaHora'">
                <span>{{ formatFecha(coordinacion.fechaCreacion) }}</span>
              </span>
            </template>
          </vue-good-table>
        </b-col>
      </b-row>
    </b-card-text>
    <div>
      <b-modal
        v-model="verDialogoDocumento"
        ok-only
        ok-variant="primary"
        ok-title="Aceptar"
        modal-class="modal-primary"
        centered
        size="lg"
        title="Detalle de la Imagen"
      >
        <b-card-text style="display: flex; justify-content: center">
          <b-img
            :src="urlImagen"
            fluid
            alt="Fluid-grow image')"
            style="max-height: 700px;"
          />
        </b-card-text>
      </b-modal>
    </div>
  </div>
</template>

<script>
import {
  BModal, BButton, VBModal, BCardText, BFormInput, BFormGroup, BRow, BCol, BImg, BFormTextarea, BTabs, BTab,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { VueGoodTable } from 'vue-good-table'
import 'vue-good-table/dist/vue-good-table.css'
import BCardActions from '@core/components/b-card-actions/BCardActions.vue'
import { calcularFecha } from '@/utils/fechas'
import ComponenteVisualizacion from '@/components/mantenimiento/coordinacionAseguradora/componenteVisualizacion.vue'

export default {
  name: 'DetalleLevantamiento',
  components: {
    ComponenteVisualizacion,
    VueGoodTable,
    BCardActions,
    BRow,
    BImg,
    BCol,
    BTab,
    BTabs,
    BModal,
    BButton,
    BCardText,
    BFormGroup,
    BFormInput,
    BFormTextarea,
  },
  directives: {
    'b-modal': VBModal,
    Ripple,
  },
  props: {
    coordinacion: {
      type: Object,
      required: false,
    },
  },
  data() {
    return {
      cordAseguradora: [],
      verDialogoDocumento: false,
      urlImagen: '',
      columns: [
        {
          label: 'Tipo',
          field: 'tipo',
          thClass: 'vgt-center-align',
          tdClass: 'text-center',
        },
        {
          label: 'Fecha/Hora',
          field: 'fechaHora',
          thClass: 'vgt-center-align',
          tdClass: 'text-center',
        },
        {
          label: 'Detalle',
          field: 'detalle',
          thClass: 'vgt-center-align',
          tdClass: 'text-center',
        },
      ],
      rows: [],
    }
  },
  methods: {
    getNombresAccesorios() {
      const primerAccidente = this.coordinacion.detalleAccidente[4]

      if (primerAccidente && primerAccidente.accesoriosAdicionales) {
        const nombres = primerAccidente.accesoriosAdicionales.map(accesorio => accesorio.nombre)

        return nombres.join(', ')
      }

      return ''
    },
    mostrarImagen(file) {
      this.urlImagen = ''
      this.urlImagen = file.url
      this.verDialogoDocumento = true
    },
    formatFecha(row) {
      return calcularFecha(row)
    },
  },
}

</script>

<style scoped>
.mi-clase-personalizada {
  /* Estilos CSS personalizados aquí */
  color: red; /* Ejemplo: Cambia el color del texto a rojo */
}
</style>
